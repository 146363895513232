import parsePhoneNumber from 'libphonenumber-js';
import { camelCase, isArray, isObject, transform } from 'lodash-es';

export function formatNumber({
  value,
  locale,
  ...options
}: { value: number; locale?: string } & Intl.NumberFormatOptions): string {
  try {
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      ...options,
    }).format(value);
  } catch (err) {
    return Number(value).toLocaleString();
  }
}

export const capitalize = (str: string) => {
  return str
    ?.split(' ')
    .map((type) => {
      if (!type) return '';
      return type[0].toUpperCase() + type.slice(1);
    })
    .join(' ');
};

const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

export const formatFileSize = (x: string | number) => {
  let l = 0,
    n = parseInt(x as string, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export const toSnakeCase = (camelCase: string) => camelCase.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const formatWebsite = (website: string) => {
  if (/^(https?):\/\//i.test(website)) return website;
  return `http://${website}`;
};

export const formatPercentage = ({
  value,
  locale = 'en',
  decimalValue = false,
  maximumDecimalDigits = 2,
  ...options
}: {
  value: number | string;
  locale?: string;
  decimalValue?: boolean;
  maximumDecimalDigits?: number;
} & Intl.NumberFormatOptions) => {
  let numericValue = Number(value);
  if (!decimalValue) numericValue = numericValue / 100; // convert to decimal as formatter expects decimal

  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: maximumDecimalDigits,
    ...options,
  }).format(numericValue);
};

export const unescapeHtml = (str: string): string => {
  return (str || '')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");
};

export const getSanitizedData = <T, K extends keyof T>(data: T | undefined, key: K) => {
  if (!data) return {} as T;
  return { ...data, [key]: unescapeHtml(data[key] as unknown as keyof K as string) };
};

export const cleanHtml = (html: string) => html.replace(/(<([^>]+)>)/gi, '');

export const getJsonSafeString = (val: undefined | null | string): string => {
  if (!val) return '';

  const sanitizedVal = val.replace(/(\r|\n|\r\n)/g, '');
  const jsonValue = JSON.stringify(sanitizedVal);
  return jsonValue.substring(1, jsonValue.length - 1);
};

type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>;

type KeysToCamelCase<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends NonNullable<unknown> ? KeysToCamelCase<T[K]> : T[K];
};

// Formats object keys to camel case (only works on level 0 properties, not deep)
export function keysToCamelCase<T extends object>(object: T): KeysToCamelCase<T> {
  return Object.entries(object).reduce(
    (memo, [key, value]) => ({
      ...memo,
      [camelCase(key)]: value,
    }),
    {} as KeysToCamelCase<T>,
  );
}

export const camelizeDeep = <T extends Record<string, unknown> & object, K extends Record<string, unknown> & object>(
  obj: T,
): K =>
  transform(obj, (result: K, value: unknown, key: string, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    // @ts-expect-error dunno
    result[camelKey] = isObject(value) ? camelizeDeep(value as K) : value;
  });

export const formatPhoneNumber = (number: string) => {
  const phoneNumber = parsePhoneNumber(number);

  return phoneNumber?.formatInternational();
};
