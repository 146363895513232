import { cx } from '@emotion/css';
import { SearchRounded } from '@mui/icons-material';
import { Option, Select } from '@sortlist-frontend/design-system';
import { useDebounceCallback } from '@sortlist-frontend/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';

import { NavigationData } from '_components/layout/Layout';
import { useNavigationContext } from '_core/context/navigation-context';
import { DEFAULT_LOCAL_ORIGIN_DOMAIN } from '_core/repos/public-api.repo';
import { useDomain } from '_core/repos/public-api/domain.repo';
import { useTopics } from '_core/repos/public-api/topics.repo';
import { trackAgenciesSearched } from '_features/search/trackers';

type Props = {
  page: string;
  cta: string;
  element: string;
  placeholder: string;
  navigationData: NavigationData;
  mobile?: boolean;
  className?: string;
};

export const getHost = (origin: string) => {
  if (['localhost', 'pr-'].some((host) => origin.includes(host))) {
    return DEFAULT_LOCAL_ORIGIN_DOMAIN;
  }
  return origin.replace('https://', '');
};

const SearchServiceInner = (props: Props) => {
  const { placeholder, navigationData, mobile = false, className, page, cta, element } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const { data: domain, isLoading } = useDomain(getHost(navigationData.origin), navigationData.origin);
  const { data: topics, isLoading: isTopicsLoading } = useTopics(
    domain,
    navigationData.locale,
    searchValue,
    navigationData.origin,
  );
  const router = useNavigationContext();

  const options: Option[] = topics?.map((topic) => ({ value: topic.url, label: topic.name })) ?? [];
  const handleInputChange = useDebounceCallback((searchValue: string) => {
    setSearchValue(searchValue);
  }, 300);

  return (
    <Select
      id={mobile ? 'search-service-select-mobile' : 'search-service-select'}
      className={cx('flex search-service-select', className)}
      placeholder={placeholder}
      options={options}
      isClearable={false}
      instanceId={mobile ? 'search-service-select-mobile' : 'search-service-select'}
      isLoading={Boolean(isLoading) || isTopicsLoading}
      inputId={mobile ? 'service-search-mobile' : 'service-search'}
      onInputChange={handleInputChange}
      onChange={(option) => {
        trackAgenciesSearched({
          page,
          cta,
          element,
          query: searchValue,
          topic: (option as Option).label,
          url: (option as Option).value,
        });

        const url = new URL(window.location.href);
        const searchParams = url.searchParams.toString();
        const targetUrl =
          searchParams.length > 0 ? `${(option as Option).value}?${searchParams}` : (option as Option).value;
        router.push(targetUrl, undefined, { scroll: true });
      }}
      components={{
        ...(searchValue == null ? { Menu: () => null } : {}),
        DropdownIndicator: () => <SearchRounded fontSize="inherit" />,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
      }}
    />
  );
};

export const SearchService = (props: Props) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5, // 5 minutes
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <SearchServiceInner {...props} />
    </QueryClientProvider>
  );
};
