'use client';

import { DeepMaybe } from '@sortlist-frontend/utils';

import { useMergedFeatureFlagConfig } from './FeatureFlagsProvider';
import {
  AvailableFeatureNames,
  AvailableFeatureToggles,
  Experiment,
  FeatureFlagValue,
  FeatureToggleConfig,
  isFeatureFlagWithExperiment,
  isFeatureFlagWithVariants,
} from './types';

export function useIsFeatureActive<
  T extends AvailableFeatureNames,
  F extends AvailableFeatureToggles[T],
  V extends DeepMaybe<F, ['variants', number]>,
  E extends DeepMaybe<F, ['experiment']> & Experiment,
>(
  featureName: T,
): {
  /**
   * boolean: true or false if the feature is enabled in any variant excluding control
   */
  isActive: boolean;
  /**
   * string: returns the currently activated variant including control
   */
  variant?: V;
  /**
   * string: returns the currently given flag, provided for type safety on the consumer side
   */
  flag: T;
  /**
   * string: currently active experiment if it is running. The value is extracted from the cookies
   */
  experiment?: E;
} {
  const featureFlagConfig = useMergedFeatureFlagConfig();
  const currentConfig = featureFlagConfig[featureName];

  // A feature is considered active if it's either true, or something other than
  // control is enabled (AB test). All controls end with -c according to conventions
  const isActive = isValueActive(currentConfig.defaultValue);
  const variant = getVariant(currentConfig);
  const experiment = getExperiment(currentConfig);

  return {
    isActive,
    variant: variant as V,
    experiment: experiment as E | undefined,
    flag: featureName,
  };
}

function getExperiment(config: FeatureToggleConfig) {
  if (isFeatureFlagWithExperiment(config)) {
    return config.experiment.isRunning ? config.experiment : undefined;
  }
}

function getVariant(config: FeatureToggleConfig) {
  const value = config.defaultValue;
  if (typeof value === 'string' && isFeatureFlagWithVariants(config)) {
    return value as keyof typeof config.variants;
  }
}

function isValueActive(v: FeatureFlagValue | boolean) {
  if (typeof v === 'string') {
    return !v.endsWith('-c') && v !== 'control';
  }
  return v;
}
