import { Monitor } from '@sortlist-frontend/mlm';
import { useQuery } from '@tanstack/react-query';

import { api } from '_core/api/api-nextjs-proxy';
import { Domain } from '_types/public-api';

const domainQuery = `
  query getDomains($host: String!) {
    domain(host: $host) {
        id
        host
        availableLocales {
            id
            code
        }
        location {
            id
            name
        }
    }
  }
`;

export const domainRepo = {
  getDomain: async (host: string, baseURL: string): Promise<Domain> => {
    const returnObject = await api.post(
      '/api/proxy/public-api',
      { query: domainQuery, variables: { host } },
      { baseURL },
    );

    const domain = returnObject?.data?.data?.domain;

    if (domain == null) {
      Monitor.captureException(
        new Error(`Domain not found for host: ${host}, response: ${JSON.stringify(returnObject?.data?.errors ?? {})}`),
      );
    }

    return domain;
  },
};

export const useDomain = (host: string, baseURL: string) => {
  return useQuery({
    queryKey: ['domain', host],
    queryFn: () => domainRepo.getDomain(host, baseURL),
  });
};
