import { useTracker } from '_core/hooks/use-tracker';

type Properties = {
  loggedIn?: boolean;
  query?: string;
  location?: string;
  industryIds?: number[];
  page?: string;
  cta?: string;
  element?: string;
  tech?: string;
  topic?: string;
  url?: string;
};

export const trackSearchCtaLoaded = (props: Properties) => {
  const { loggedIn, query, location, industryIds, page, element } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('searchCtaLoaded', {
    loggedIn,
    query,
    location,
    industryIds,
    page,
    element,
  });
};

export const trackAgenciesSearched = (props: Properties) => {
  const { loggedIn, query, location, industryIds, page, element, tech, topic, url } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('agenciesSearched', {
    loggedIn,
    query,
    location,
    industryIds,
    page,
    element,
    tech,
    topic,
    url,
  });
};

export const trackComingSoonHovered = (props: Properties) => {
  const { page, element } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('comingSoonHovered', {
    page,
    element,
  });
};
