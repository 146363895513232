import { Monitor } from '@sortlist-frontend/mlm';
import { capitalize } from '@sortlist-frontend/utils';
import { useQuery } from '@tanstack/react-query';

import { api } from '_core/api/api-nextjs-proxy';
import { acceptLanguageHeader } from '_core/api/api.utils';
import { Domain, SearchTopic, TopicExternalType } from '_types/public-api';

const topicsQuery = `
  query searchTopics($searchText: String!, $topicTypes: [TopicExternalType!]!, $host: String!, $associatedTopics: [ID!], $locale: ID!) {
    searchTopics(
      searchText: $searchText,
      types: $topicTypes,
      pageFilter: {
              host: $host,
              locale: $locale,
              topics: $associatedTopics
          }
      ) {
      edges {
        node {
          ...on NamedTopic {
            name
          }
        }
        page {
          id
          url
        }
      }
    }
  }
`;

const SERVICE_RELATED_TOPIC_TYPES: TopicExternalType[] = [
  'CATEGORY',
  'EXPERTISE',
  'FEATURE',
  'FRAMEWORK',
  'PLATFORM',
  'SKILL',
];

type SearchTopicResponse = {
  node: {
    name: string;
  };
  page: {
    id: string;
    url: string;
  };
};

export type TopicsParams = {
  topicTypes: TopicExternalType[];
  host: string;
  associatedTopics: string[];
  locale: string;
};

export const topicsRepo = {
  getTopics: async (
    params: TopicsParams,
    locale: string,
    searchText: string,
    baseURL: string,
  ): Promise<SearchTopic[]> => {
    if (params == null) {
      return [];
    }
    const returnObject = await api.post(
      '/api/proxy/public-api',
      { query: topicsQuery, variables: { ...params, searchText } },
      { baseURL, headers: { 'Accept-Language': acceptLanguageHeader(locale) } },
    );

    const response = returnObject?.data;
    const results: SearchTopicResponse[] = response?.data?.searchTopics?.edges ?? [];

    if (response?.errors != null) {
      Monitor.captureException(
        new Error(`Error for searchTopics: ${JSON.stringify(returnObject?.data?.errors ?? {})}`),
      );
    }

    return results
      .map((result) => ({
        name: capitalize(result.node.name),
        url: result.page.url,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
};

export const useTopics = (domain: Domain | undefined, locale: string, searchText: string, baseURL: string) => {
  return useQuery({
    queryKey: ['topics', domain?.host ?? 'nodomain', locale, searchText],
    queryFn: () => {
      if (domain == null) {
        return Promise.resolve([]);
      }
      const localeId = domain.availableLocales.find((domainLocale) => domainLocale.code === locale)?.id;
      if (localeId == null) {
        return Promise.resolve([]);
      }

      const params: TopicsParams = {
        topicTypes: SERVICE_RELATED_TOPIC_TYPES,
        host: domain.host,
        associatedTopics: [localeId, domain.location.id],
        locale: localeId,
      };

      return topicsRepo.getTopics(params, locale, searchText, baseURL);
    },
    enabled: Boolean(domain != null),
  });
};
