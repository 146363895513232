import { ServerResponse } from 'http';

export const acceptLanguageHeader = (locale: string) => `${locale}${locale !== 'en' ? ', en;q=0.6' : ''}, *;q=0.5`;

export const getCommonHeaders = (accessToken?: string, locale?: string) => {
  const headers = {} as { Authorization?: string; 'Accept-Language'?: string };

  if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
  if (locale) headers['Accept-Language'] = acceptLanguageHeader(locale);

  return headers;
};

export const disableCache = (res: ServerResponse) => {
  // As per Fastly recommendation, we can use these headers to make sure it's not cached on every browser.
  // See: https://docs.fastly.com/en/guides/temporarily-disabling-caching#disabling-caching-at-the-browser-level
  res.setHeader(
    'Cache-Control',
    'no-cache, no-store, private, must-revalidate, max-age=0, max-stale=0, post-check=0, pre-check=0',
  );
  res.setHeader('Pragma', 'no-cache');
  res.setHeader('Expires', '0');
};
